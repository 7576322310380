<template>
  <div id="chart">
    <apexchart
      type="rangeBar"
      height="200"
      :options="chartOptions"
      :series="chartdata"
    />
  </div>
</template>

<script>
  export default {
    name: 'ChartTimeLine',
    props: { chartdata: { type: Array, required: true } },
    data () {
      return {
        chartOptions: {
          chart: {
            height: 350,
            type: 'rangeBar',
          },
          plotOptions: {
            bar: {
              horizontal: true,
              barHeight: '50%',
              rangeBarGroupRows: true,
            },
          },
          fill: {
            type: 'solid',
          },
          xaxis: {
            type: 'datetime',
            labels: { format: 'HH:mm' },
          },
          legend: {
            position: 'right',
          },
          tooltip: {
            custom: function (opts) {
              return ''
            },
          },
        },
      }
    },
    created () {},
    methods: {},
  }
</script>

<style lang="scss" scoped></style>
