<template>
  <v-card tile>
    <chart-time-line
      v-if="loaded"
      :chartdata="sessionSeries.data"
    />
  </v-card>
</template>

<script>
  import UserService from '@/services/user.service'
  import ChartTimeLine from '../components/ChartTimeLine.vue'

  export default {
    name: 'Test',
    components: { ChartTimeLine },

    data () {
      return {
        loaded: false,
        sessionSeries: null,
        chartData: null,
      }
    },

    async created () {
      const data = await UserService.getUserSessionData()

      const sessionsList = data.data
      console.log('sessionsList')
      sessionsList.forEach(session => {
      // if the session is selected
      })
    },

    async mounted () {
      this.loaded = false
      this.chartData = this.getDataFromOneSession(3131)
      this.loaded = true
    },
    methods: {
      getDataFromOneSession (id) {
        UserService.getUserSessionActionData(id).then(response => {
          const actions = response.data
          const zerotime = actions[0].timestamp
          this.sessionSeries = { data: [] }
          actions.forEach(action => {
            console.log(action.id)
            console.log(action.timestamp - zerotime)

            this.sessionSeries.data.push({
              name: action.label,
              data: [{ x: 'Sesion', y: [0, action.timestamp - zerotime] }],
            })
          })
        })
      },
    },
  }
</script>
